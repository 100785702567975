.presentation-selector {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .box {
        width: 100%;
        min-height: 200px;
        flex: 1 1 auto;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        flex-direction: column;
        .box-container {
            position: relative;
            margin: 8px 0;
            padding: 60px 0;
            border: 10px solid #028bd3;
            border-radius: 25px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            .title {
                font-weight: bold;
                font-size: 36px;
                text-align: center;
            }

            .author {
                font-size: 16px;
            }
        }
    }

    h3 {
        font-size: 24px;
        text-align: center;
    }

    .progress-container {
        width: 80%;
        position: absolute;
        bottom: 20px;
        font-size: 16px;
        text-align: center;

        .progress {
            height: 16px;
        }

        .bg-custom {
            background-color: #028bd3;
        }

        .progress-text {
            margin-bottom: 4px;
        }
    }
}
